
import Vue from "vue"
import { getItem, setItem, clearContext } from '@/store/localStorage'
import context from "@/router/middleware/context"
import { Fetched } from "../types/responses"
import { GrowthbookFeatureFlags, VerjiGrowthBook } from "@/utilities/growthbook"

const AsyncLoading = () => import("@/components/AsyncLoading.vue")
export default Vue.extend({
	name: "Context",
	components: {
		AsyncLoading
	},
	mounted() {
		this.getDomains()
	},
	data(): {
		selectedCustomerId: null | string
		selectedTenantId: null | string
		loadingDomains: boolean
		loading: boolean
	} {
		return {
			selectedCustomerId: null,
			selectedTenantId: null,
			loadingDomains: false,
			loading: false,
		}
	},
	computed: {
		customers(): Array<{ text: string, value: null|string}> {
			const arr: Array<{ text: string, value: null|string}> = []
			arr.push({ text: "NONE", value: null})
			const domains = this.$vStore.state.context.domains
			.filter(domain => {
				return domain.tenantType === "Customer"
			})
			.map(domain => {
				return { text: domain.name, value: domain.id}
			})
			const selectList = [...arr, ...domains]
			return selectList
		},
		fetched(): boolean {
			return this.$vStore.state.context.fetched.domains
		},
        isTenantOnboarder(): boolean {
            return this.$vStore.state.context.anyDomainPermissions.isTenantOnboarder
        }
	},
	methods: {
        isManageTenantsAllowed(): boolean {
            console.log("Is Manage Tenants Allowed function running...")
            const isAllowed: boolean = VerjiGrowthBook.getInstance().isOn(GrowthbookFeatureFlags.ManageTenants)
            console.log("Calculated manage tenants allowed to: ", isAllowed)
            return isAllowed
        },
		async getDomains(): Promise<void> {
			this.loadingDomains = true
			
			// Determine if user has previously selected a customer
			const cookieSelectedCustomer = getItem("selectedCustomer");
			const _domains = getItem("domains");
			const _domainSize = parseInt(getItem("domainSize") ?? 0);
			let loadedFromLs = false
			if(
				_domainSize && _domainSize > 0 && _domains && _domains != "" && 
				this.$vStore.state.context.domains.length < 1
			){
				await this.$vStore.dispatch("context/loadDomains", JSON.parse(_domains));
				loadedFromLs = true
			}
			if(!this.fetched && !loadedFromLs){
				await this.$vStore.dispatch("context/updateDomains")
				this.setSelectionToLocalStorage()
				const fetched: Fetched = {
					domains: true
				}
				await this.$vStore.dispatch("context/setFetched", fetched)
			}

			const contextDomains = this.$vStore.state.context.domains;
			if(contextDomains && contextDomains.length < 1) await this.$vStore.dispatch("context/updateDomains")

			const hit = this.$vStore.state.context.domains.find(domain => domain.id === cookieSelectedCustomer)
			if(!hit) clearContext()

			// SET and REDIRECT if only one customer!
			if (this.$vStore.state.context.domains[0] && this.$vStore.state.context.domains.length === 1 && !this.fetched) {
				await this.redirectToOnlyCustomer()
			} else if (hit && !this.fetched){
				this.selectedCustomerId = hit.id
				this.$vStore.dispatch("context/setCustomer", hit)
				this.setSelectionToLocalStorage()

				await this.$vStore.dispatch("nav/updateModules")
				if (!(this.$router.currentRoute.path === "/") && this.$vStore.state.context.selectedCustomer?.id) {
					this.$router.push("/")
				}
			} else {
				if(
					(!cookieSelectedCustomer || cookieSelectedCustomer == "") &&
					(this.$vStore.state.context.domains[0] && this.$vStore.state.context.domains.length === 1))
				{
					await this.redirectToOnlyCustomer()
				}
				this.selectedCustomerId = hit?.id ?? null
				this.loadingDomains = false
			}
		},
		async redirectToOnlyCustomer(): Promise<void>{
			const theOneAndOnly = this.$vStore.state.context.domains[0]
			this.selectedCustomerId = theOneAndOnly.id
			this.$vStore.dispatch("context/setCustomer", theOneAndOnly)
			this.setSelectionToLocalStorage()

			await this.$vStore.dispatch("nav/updateModules")
			if (!(this.$router.currentRoute.path === "/") && this.$vStore.state.context.selectedCustomer?.id) {
				await this.$router.push("/") // Await this to prevent render staggering
			}
		},
        createTenant(): void {
            this.$router.push({path:'/tenant', query: { tab: "1"} }).catch(() => { console.log("Redirected from context")})
        },
		async setCustomer(): Promise<void> {
			this.loading = true
			if (this.selectedCustomerId) {
				const hit = this.$vStore.state.context.domains.find(domain => domain.id === this.selectedCustomerId)
				if (hit) {
					this.$vStore.dispatch("context/setCustomer", hit)
					this.setSelectionToLocalStorage()
				} else {
					this.$vStore.dispatch("context/setCustomer", null)
				}
			} else {
				this.$vStore.dispatch("context/setCustomer", null)
			}

			this.$vStore.dispatch("context/setClient", null)

			/****************************/
			/** RESET SOME VUEX STATES **/
			/****************************/
			this.$vStore.dispatch("context/resetPersons")
			this.$vStore.dispatch("context/resetCustomerInfo")
			this.$vStore.dispatch("context/resetCustomerClients")
			this.$vStore.dispatch("context/resetClientInfo")
			this.$vStore.dispatch("context/resetClientPersons")

			this.$vStore.dispatch("context/resetFindPersonAccount")
			this.$vStore.dispatch("context/resetAccountSummary")
			this.$vStore.dispatch("context/resetClientInvitations")

			this.$vStore.dispatch("acl/resetState")
			this.$vStore.dispatch("provisioning/resetState")
			this.$vStore.dispatch("nav/resetState")
			await this.$vStore.dispatch("nav/updateModules")

			this.loading = false
			if (!(this.$router.currentRoute.path === "/") && this.$vStore.state.context.selectedCustomer?.id) {
				// eslint-disable-next-line
				this.$router.push("/")
			}
		},
		setSelectionToLocalStorage(): void {
			setItem("selectedCustomer", this.selectedCustomerId ?? "")
			setItem("domainSize", this.$vStore.state.context.domains.length.toString() ?? "")
			setItem("domains", JSON.stringify(this.$vStore.state.context.domains) ?? "")
		}
	},
})
